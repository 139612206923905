function App() {
    return (
        <div className='content-wrapper'>
            <div className='text-center'>
                <div className='mb-5'>
                    <img src='/logos/logo-to-light.svg' alt='logo' srcset='/logos/logo-to-light.svg' className="w-100" />
                </div>
                <div>
                    <a href='https://api.whatsapp.com/send/?phone=5585999098831&text=Olá, Jozi! Venho do Site da JF Beauté e quero saber mais' className='btn btn-success btn-lg' target='_blank' rel='noopener noreferrer'>
                        <h3>
                            <i className='fa-brands fa-whatsapp'></i> Fale com a Jozi
                        </h3>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default App;
